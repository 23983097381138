<template>
  <CRow>
    <CCol :xs="12">
      <DocsCallout name="Progress" href="components/progress.html" />
    </CCol>
    <CCol :xs="12">
      <CCard class="mb-4">
        <CCardHeader>
          <strong>Vue Progress</strong> <small>Basic example</small>
        </CCardHeader>
        <CCardBody>
          <p class="text-medium-emphasis small">
            Progress components are built with two HTML elements, some CSS to
            set the width, and a few attributes. We don&#39;tuse
            <a
              href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/progress"
            >
              the HTML5 <code>&lt;progress&gt;</code> element
            </a>
            , ensuring you can stack progress bars, animate them, and place text
            labels over them.
          </p>
          <DocsExample href="components/progress.html">
            <CProgress class="mb-3">
              <CProgressBar :value="0" />
            </CProgress>
            <CProgress class="mb-3">
              <CProgressBar :value="25" />
            </CProgress>
            <CProgress class="mb-3">
              <CProgressBar :value="50" />
            </CProgress>
            <CProgress class="mb-3">
              <CProgressBar :value="75" />
            </CProgress>
            <CProgress class="mb-3">
              <CProgressBar :value="100" />
            </CProgress>
          </DocsExample>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol :xs="12">
      <CCard class="mb-4">
        <CCardHeader>
          <strong>Vue Progress</strong> <small>Labels</small>
        </CCardHeader>
        <CCardBody>
          <p class="text-medium-emphasis small">
            Add labels to your progress bars by placing text within the
            <code>&lt;CProgressBar&gt;</code>.
          </p>
          <DocsExample href="components/progress.html#labels">
            <CProgress class="mb-3">
              <CProgressBar :value="25">25%</CProgressBar>
            </CProgress>
          </DocsExample>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol :xs="12">
      <CCard class="mb-4">
        <CCardHeader>
          <strong>Vue Progress</strong> <small>Height</small>
        </CCardHeader>
        <CCardBody>
          <p class="text-medium-emphasis small">
            We only set a <code>height</code> value on the
            <code>&lt;CProgress&gt;</code>, so if you change that value the
            inner <code>&lt;CProgressBar&gt;</code> will automatically resize
            accordingly.
          </p>
          <DocsExample href="components/progress.html#height">
            <CProgress :height="1" class="mb-3">
              <CProgressBar :value="25"></CProgressBar>
            </CProgress>
            <CProgress :height="20" class="mb-3">
              <CProgressBar :value="25"></CProgressBar>
            </CProgress>
          </DocsExample>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol :xs="12">
      <CCard class="mb-4">
        <CCardHeader>
          <strong>Vue Progress</strong> <small>Backgrounds</small>
        </CCardHeader>
        <CCardBody>
          <p class="text-medium-emphasis small">
            Use <code>color</code> prop to change the appearance of individual
            progress bars.
          </p>
          <DocsExample href="components/progress.html#backgrounds">
            <CProgress class="mb-3">
              <CProgressBar color="success" :value="25" />
            </CProgress>
            <CProgress class="mb-3">
              <CProgressBar color="info" :value="50" />
            </CProgress>
            <CProgress class="mb-3">
              <CProgressBar color="warning" :value="75" />
            </CProgress>
            <CProgress class="mb-3">
              <CProgressBar color="danger" :value="100" />
            </CProgress>
          </DocsExample>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol :xs="12">
      <CCard class="mb-4">
        <CCardHeader>
          <strong>Vue Progress</strong> <small>Multiple bars</small>
        </CCardHeader>
        <CCardBody>
          <p class="text-medium-emphasis small">
            Include multiple progress bars in a progress component if you need.
          </p>
          <DocsExample href="components/progress.html#multiple-bars">
            <CProgress class="mb-3">
              <CProgressBar :value="15" />
              <CProgressBar color="success" :value="30" />
              <CProgressBar color="info" :value="20" />
            </CProgress>
          </DocsExample>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol :xs="12">
      <CCard class="mb-4">
        <CCardHeader>
          <strong>Vue Progress</strong> <small>Striped</small>
        </CCardHeader>
        <CCardBody>
          <p class="text-medium-emphasis small">
            Add <code>variant=&#34;striped&#34;</code> to any
            <code>&lt;CProgressBar&gt;</code> to apply a stripe via CSS gradient
            over the progress bar&#39;s background color.
          </p>
          <DocsExample href="components/progress.html#striped">
            <CProgress class="mb-3">
              <CProgressBar color="success" variant="striped" :value="25" />
            </CProgress>
            <CProgress class="mb-3">
              <CProgressBar color="info" variant="striped" :value="50" />
            </CProgress>
            <CProgress class="mb-3">
              <CProgressBar color="warning" variant="striped" :value="75" />
            </CProgress>
            <CProgress class="mb-3">
              <CProgressBar color="danger" variant="striped" :value="100" />
            </CProgress>
          </DocsExample>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol :xs="12">
      <CCard class="mb-4">
        <CCardHeader>
          <strong>Vue Progress</strong> <small>Animated stripes</small>
        </CCardHeader>
        <CCardBody>
          <p class="text-medium-emphasis small">
            The striped gradient can also be animated. Add
            <code>animated</code> property to
            <code>&lt;CProgressBar&gt;</code> to animate the stripes right to
            left via CSS3 animations.
          </p>
          <DocsExample href="components/progress.html#animated-stripes">
            <CProgress class="mb-3">
              <CProgressBar
                color="success"
                variant="striped"
                animated
                :value="25"
              />
            </CProgress>
            <CProgress class="mb-3">
              <CProgressBar
                color="info"
                variant="striped"
                animated
                :value="50"
              />
            </CProgress>
            <CProgress class="mb-3">
              <CProgressBar
                color="warning"
                variant="striped"
                animated
                :value="75"
              />
            </CProgress>
            <CProgress class="mb-3">
              <CProgressBar
                color="danger"
                variant="striped"
                animated
                :value="100"
              />
            </CProgress>
          </DocsExample>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'Progress',
}
</script>
